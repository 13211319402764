/**
 * Switch current language.
 * @param lang to switch to
 */
window.switchLanguage = (lang = 'de') => {
  const parser = new URL(window.location);
  parser.searchParams.set('lang', lang);
  window.location = parser.href;
};

/*
import {
  mdiAccount,
  mdiAlertOutline,
  mdiAsterisk,
  mdiBarcode,
  mdiCheckOutline,
  mdiClockOutline,
  mdiDeleteOutline,
  mdiEmailOutline,
  mdiFileDelimitedOutline,
  mdiFilterOutline,
  mdiLock,
  mdiLockPlus,
  mdiLockReset,
  mdiLogin,
  mdiLogout,
  mdiPencilOutline,
  mdiUpload,
} from '@mdi/js';

const icons = {
  account: mdiAccount,
  alert: mdiAlertOutline,
  asterisk: mdiAsterisk,
  barcode: mdiBarcode,
  check: mdiCheckOutline,
  clock: mdiClockOutline,
  csv: mdiFileDelimitedOutline,
  delete: mdiDeleteOutline,
  edit: mdiPencilOutline,
  email: mdiEmailOutline,
  filter: mdiFilterOutline,
  lock: mdiLock,
  lockPlus: mdiLockPlus,
  lockReset: mdiLockReset,
  login: mdiLogin,
  logout: mdiLogout,
  password: mdiLock,
  upload: mdiUpload,
};
*/

/**
 * Replace all mdi-icons.
 */
/*
document.querySelectorAll('[mdi-icon]').forEach((node) => {
  const icon = node.getAttribute('mdi-icon');

  if (icon in icons) {
    const iconD = icons[icon];
    const path = node.querySelector('path');
    path.setAttribute('d', iconD);
  } else {
    console.warn('Icon missing', icon);
  }
});
*/
